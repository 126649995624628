import {
	ApButtonMain,
	ApButtonSecondary,
	ApChip,
	ApIcon,
	ApInput,
	ApModal,
	IOption,
} from '@alixpartners/ui-components';
import React, { useState } from 'react';
import { MultiSelectCreatable } from '../../MultiSelectCreatable';

export const ManageColumns: React.FC<any> = (props: any) => {
	const [selectedNewColumn, setSelectedNewColumn] = useState({} as any);
	const [customColumnName, setCustomColumnName] = useState('');
	const [customColumnValueName, setCustomColumnValueName] = useState('');

	return (
		<div className="prospect-widget" style={{ width: '100%' }}>
			<div className="prospect-widget__title">
				<div>Columns</div>
				<div>
					<ApButtonSecondary onClick={props.handleManageColumnsOpen}>
						<div className="button-wrapper">
							<ApIcon iconName="baseline_add" iconColor="#333333"></ApIcon>
							<div>Add New Column</div>
						</div>
					</ApButtonSecondary>
				</div>
			</div>
			<div className="prospect-widget__content">
				{props.selectedColumnsFiltered.map((t: any) => {
					return (
						<ApChip
							onClick={() => props.handleRemoveColumn(t)}
							className="prospect-widget__tag"
							key={t.value}
							iconName="outline_cancel"
						>
							{t.label}
						</ApChip>
					);
				})}
			</div>

			{
				<ApModal
					isDismissible
					className="prospect-widget__tag-modal"
					isOpen={props.isManageColumnsOpen}
					hasClosed={props.handleCloseColumnsModal}
					header="Manage Columns"
				>
					<div className="modal__content">
						<div className="input-form">
							<label className="comment-modal__label">Columns</label>
							<MultiSelectCreatable
								options={[
									...props.distinctSelectedColumns,
									{ label: 'Custom Column', value: 'custom' },
								]}
								onCreateOption={setSelectedNewColumn}
								onChange={setSelectedNewColumn}
							/>
						</div>
						<br />
						{selectedNewColumn.value === 'custom' && (
							<div className="input-form">
								<label className="comment-modal__label">Custom column</label>
								<ApInput
									name="custom-column-name"
									placeholder="Custom Column"
									onChange={setCustomColumnName}
									value={customColumnName}
								/>
								<ApInput
									name="custom-column-value-name"
									placeholder="Field value"
									onChange={setCustomColumnValueName}
									value={customColumnValueName}
								/>
							</div>
						)}
					</div>
					<br />
					<br />
					<div className="modal__footer">
						<ApButtonSecondary onClick={props.handleCloseColumnsModal}>
							Cancel
						</ApButtonSecondary>
						<ApButtonMain
							disabled={
								selectedNewColumn.value === 'custom' && !customColumnName
							}
							onClick={() =>
								props.handleAddNewColumn(
									customColumnName,
									selectedNewColumn,
									customColumnValueName,
									props.section,
								)
							}
						>
							Add
						</ApButtonMain>
					</div>
				</ApModal>
			}
		</div>
	);
};
