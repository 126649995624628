/**
 * Used by as the central source for default TypeScript types
 */
export const LIGHT = 'light';
export const DARK = 'dark';

type TLIGHT = typeof LIGHT;
type TDARK = typeof DARK;

export type TStringUndefined = string | undefined;
export type TString = string | null | undefined;
export type TNumber = number | null;

export interface IDefaultProps {
	/** A css class */
	className?: string;
}

export class FilterType {
	static INDUSTRY = 'Industry';
	static TIME = 'Time';
	static GEOGRAPHY = 'Geography';
	static PROVIDER = 'Provider';
}

export class ChartType {
	static FINANCIAL = 'Financial';
	static STOCK = 'Stock';
}

export class FinancialType {
	static REVENUE = 'Revenue';
	static GROSS_PROFIT = 'Gross Profit';
	static SG_AND_A = 'SG & A';
	static EBITDA = 'EBITDA';
	static OPERATING_INCOME = 'Operating Income';
	static TOTAL_DEBT = 'Total Debt';
	static NET_INCOME = 'Net Income';
	static COGS = 'COGS';
	static YEARS = 'YEARS';
}

export class WidgetType {
	static FINANCIALS = 'Financials';
	static SHARE_PRICE = 'Share Price';
	static ENGAGEMENTS_SPEND = 'Engagements Won';
	static GEOGRAPHY = 'Geography';
	static PEOPLE = 'People';
	static CONNECTIONS = 'Connections';
	static SUMMARY = 'Summary';
	static CLASSIFICATION = 'AP Classification';
	static NEWS = 'News';
	static ENGAGEMENTS = 'Engagements';
	static WATCHLISTS = 'Watchlists';
}

export class CommentType {
	static OTHER = 'Other';
	static EXECUTIVE = 'Executive';
	static SECTION = 'Section';
}

export class SectionType {
	static NONE = 'None';
	static SUMMARY = 'Summary';
	static PROFIT_AND_LOSS = 'ProfitAndLoss';
	static DEBT = 'Debt';
	static CASHPOSITION_AND_CASH_FLOW = 'CashPositionAndCashFlow';
	static PROFIT_AND_LOSS_OVERVIEW = 'ProfitAndLossOverView';
	static BALANCE_SHEET = 'BalanceSheet';
	static CASHFLOW = 'Cashflow';
	static PEERS_SHARE_PRICE = 'PeersSharePrice';
	static PEERS_REVENUE_COMPARISON = 'PeersRevenueComparison';
	static PEERS_FINANCIAL_DATA = 'PeersFinancialData';
}

export class TagType {
	static COMPANY = 'Company';
	static ANALYSIS_COMMENT = 'AnalysisComment';
}

export const monthsList = [
	'',
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const monthsMap = [
	{ label: 'January', value: '0' },
	{ label: 'February', value: '1' },
	{ label: 'March', value: '2' },
	{ label: 'April', value: '3' },
	{ label: 'May', value: '4' },
	{ label: 'June', value: '5' },
	{ label: 'July', value: '6' },
	{ label: 'August', value: '7' },
	{ label: 'September', value: '8' },
	{ label: 'October', value: '9' },
	{ label: 'November', value: '10' },
	{ label: 'December', value: '11' },
];

export interface IOverviewWidget {
	name: string;
	component: any;
	type: 'main' | 'sidebar';
	minHeight?: number;
	props?: any;
	disableExport?: boolean;
	isHidden?: boolean;
	cachedData?: any;

	api?(): Promise<any>;

	processData?(data: any): any;

	onDataLoaded?(data: any): any;
}

export interface IFilter {
	id: string;
	subFilters?: string[];
	type: string;
	value: string;
}

export interface IPagination {
	pageNumber: number;
	pageSize: number;
}

export interface IEngagement {
	name: string;
	minSpend: string;
	maxSpend: string;
	contracts: number;
	transactions: number;
}

export interface ICompanyEngagement {
	caId: number;
	type: string;
	count: number;
	years: string;
	captains: string;
	pitchCaptains: string[] | null; // Added as it already exsists in the API as an array of names
	toolTip: string;
}

export interface ITaxonomy {
	taxonomyId: number;
	taxonomyName: string;
}

export interface ICompanySearchResult {
	additionalNames: string[];
	companyId: number;
	description: string;
	engagements: IEngagement[];
	headOfficeLocation: string;
	industry: string;
	logoUrl?: string;
	name: string;
	classification: IClassification;
	website?: string;
}

export interface IClassification {
	industryId: number;
	industry: string;
	industryGroup: string;
	sector: string;
	subIndustry: string;
}

export interface ICompany {
	additionalNames: string[];
	city: string;
	classification: IClassification;
	companyId: number;
	companyTypeName: string;
	country: string;
	currencyCode: string;
	dayFounded: number;
	description: string;
	engagements: ICompanyEngagement[];
	exchangeName: string;
	logoUrl?: string;
	monthFounded: number;
	name: string;
	tickerSymbol: string;
	website?: string;
	yearFounded: number;
}

// Finalized interface
export interface IPeople {
	companyId: number;
	email?: string;
	id: number;
	isBoardMember?: boolean;
	name: string;
	proRank: number;
	relationshipType?: string; // TODO: remove this property
	relationships: IApEmployee[];
	role?: string;
}

export interface IChartData {
	name: string;
	data: number[];
}

export interface IChart {
	chartName: string;
	type: string;
	xAxis: {
		categories: string[];
	};
	series: {
		data: IChartData[];
	};
}

export interface IGeography {
	countries: string[];
	keyCountries: string[];
}

// Finalized interface
export interface ISummary {
	companyId: number;
	country: string;
	employees: number;
	grossProfit: number;
	industry: string;
	keyOwnership: string;
	latestYear: number;
	leadership: string;
	leadershipTitle: string;
	marketCap: number;
	netDebt: number;
	netIncome: number;
	operatingIncome: number;
	revenue: number;
	sharePrice: number;
	sharePrice52WeekHigh: number;
	tickerSymbol: string;
	totalDebt: number;
	totalEbitda: number;
	website: string;
}

export interface ICompetitorMeta {
	companyId: number;
	name: string;
}

export interface ICompanyData {
	acquisitions: string[]; // TODO: move into company?
	company: ICompany;
	competitors: ICompetitorMeta[];
	people: IPeople[];
	subsidaries: string[]; // TODO: move into company?
	charts: IChart[];
	geography: IGeography;
	watchlists: IWatchlist[];
	summary: ISummary;
}

// Finalized interface
export interface IApEmployee {
	id: number;
	// relationshipId: string; // TODO: this is missing in api atm
	name: string;
	role: string;
	email?: string;
}

export interface ICompetitorFinancialData {
	cashConversion: number;
	cogs: number;
	ebit: number;
	ebitMargin: number;
	ebitda: number;
	ebitdaGrowth5Y: number;
	ebitdaMargin: number;
	ebitdacagR5Y: number;
	fiscalYear: number;
	grossProfit: number;
	grossProfitCAGR5Y: number;
	grossProfitGrowth5Y: number;
	inventoryTurnover: number;
	leveredFCF: number;
	netDebtEbitda: number;
	netIncome: number;
	netRentalExpense: number;
	operatingIncome: number;
	periodEndDate: string;
	revenue: number;
	revenueCAGR5Y: number;
	revenueGrowth5Y: number;
	salesMarketing: number;
	sga: number;
	sgaGrowth5Y: number;
	sgaMargin: number;
	sgacagR5Y: number;
	totalRevenue3YrCAGR: number;
	totalRevenueEmployee: number;
}

export interface ICompetitorData {
	companyId: number;
	financialData: ICompetitorFinancialData[];
	name: string;
	website: string;
}

export interface ICompetitor extends ICompetitorData {
	companyId: number;
	financialData: ICompetitorFinancialData[];
	sharePriceGrowth: number | null;
	sharePriceData: IPeersSharePriceData[];
	// currentRevenueData: IPeersRevenueYearData | null;
	// previousRevenueData: IPeersRevenueYearData | null;
	name: string;
	website: string;
}

export interface IBalanceOverview {
	accountPayable: number;
	accountReceivable: number;
	cashAndEquivalents: number;
	currentAssets: number;
	currentPortionOfLongTermDebt: number;
	fiscalYear: number;
	goodwill: number;
	inventory: number;
	longTermDebt: number;
	netPropertyPlanAndEquipment: number;
	nonCurrentAssets: number;
	otherCurrentAssets: number;
	otherCurrentLiabilitis: number;
	otherNonCurrentAssets: number;
	otherNonCurrentLiabilities: number;
	totalAssets: number;
	totalEquity: number;
	totalLiabilitiesAndEquity: number;
}

export interface ICashflowOverview {
	capitalExpenditure: number;
	cashGeneratedFromFinancing: number;
	cashGeneratedFromInvesting: number;
	cashGeneratedFromOperations: number;
	depreciationAmortization: number;
	fiscalYear: number;
	fxEffect: number;
	investmentsInMarketableAndEquitySecurities: number;
	netInCashAndEquivalents: number;
	otherFinancingActivities: number;
	profitOfTheYear: number;
	totalDebtIssued: number;
	totalDebtRepaid: number;
}

export interface IKpi {
	dataItemId: number;
	description: string;
	hurdleRate: number;
	kpiWeighting: number;
	year1Change1Value: number;
	year1Change2Value: number;
	year2Change1Value: number;
	year2Change2Value: number;
}

export interface ICategory {
	categoryName: string;
	categoryWeighting: number;
	kpis: IKpi[];
}

export interface IGridCategory {
	categoryName: string;
	categoryWeighting: number;
	dataItemId: number;
	description: string;
	hurdleRate: number;
	kpiWeighting: number;
	year1Change1Value: number;
	year1Change2Value: number;
	year2Change1Value: number;
	year2Change2Value: number;
}

export interface INews {
	category: string;
	datePublished: string;
	description: string;
	image: {
		thumbnail: {
			contentUrl: string;
			width: number;
			height: number;
		};
	};
	name: string;
	url: string;
}

export interface IWatchlistListItem {
	createdOn: string;
	numberOfCompanies: number;
	numberOfSubscribers: number;
	status: number;
	watchlistId: number;
	watchlistName: string;
	propsectStatusCount: {
		drop: number;
		monitor: number;
		pursuit: number;
	};
}

export interface AgGridColumnItem {
	headerName: string;
	field: string;
	sortable?: boolean;
	filter?: boolean;
	resizable?: boolean;
	singleClickEdit?: boolean;
	width?: number;
	colId?: string;
	cellRenderer?: unknown;
}

export interface ICompanyListItem {
	companyId: number;
	companyName: string;
	industry: string;
	industryId: number;
	watchlists: {
		companyStatus: string;
		numberOfUsers: number;
		watchlistId: number;
		watchlistName: string;
	}[];
}

// TODO: rename to ISubscriber
export interface IPerson {
	userId: string;
	firstName: string;
	lastName: string;
	email: string;
}

export interface IUser {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
}

export interface IProspect {
	companyId: number;
	companyName: string;
	notes: string;
	// owner: IPerson;
	owners: IPerson[];
	status: string;
}

export interface IWatchlistProspectMeta {
	companyId: number;
	companyName: string;
	ebitda: number;
	industry: string;
	owners: string;
	status: string;
	revenue: number;
	revenueInUsd: number;
	totalDebt: number;
	tasks: number;
	currencyCode: string;
	lastCommentReviewedOn: string;
	latestCommentCreatedOn: string;
	watchlists: { watchlistId: number; watchlistName: string }[];
}

export interface IWatchlistProspectList {
	companyId: number;
	companyName: string;
	ebitda: string;
	industry: string;
	owners: string;
	status: string;
	revenue: string;
	revenueInUsd: string;
	totalDebt: string;
	tasks: number;
	currencyCode: string;
	lastCommentReviewedOn: string;
	latestCommentCreatedOn: string;
	watchlists: { watchlistId: number; watchlistName: string }[];
	revenueValue: number;
	revenueInUsdValue: number;
	ebitdaValue: number;
	totalDebtValue: number;
	currencySign: string;
}

export interface IMaterialDebt {
	id: number;
	date: string;
	debt: number;
}

export interface IFinancialSummary {
	calendarYear: number;
	cash: number;
	companyId: number;
	currencyCode: string | null;
	ebitdaGrowth: number;
	interestCoverage: number;
	isManualyEdited: boolean;
	marketCap: number;
	materialDebt: IMaterialDebt[];
	periodEndDate: string;
	revenueGrowth: number;
	totalDebt: number;
	totalDebtOverEBITDA: number;
	totalEBITDA: number;
	totalRevenue: number;
}

export interface IWatchlistCurrency {
	currencyId: number;
	currencyCode: string;
}

interface IWatchlistCommunity {
	id: number;
	name: string;
}

interface IWatchlistIndustry {
	id: number;
	name: string;
}

export interface IWatchlist {
	createdOn: string;
	description: string;
	prospects: IWatchlistProspectMeta[];
	status: string;
	subscribers: IPerson[];
	watchlistId: number;
	watchlistName: string;
	companyTimeline: ICompanyTimeline;
	communities: IWatchlistCommunity[];
	industries: IWatchlistIndustry[];
}

export interface ICompanyTimeline {
	companiesAdded: number;
	companiesRemoved: number;
	// data: { date: string; numberOfCompanies: number }[];
	numberOfCompanies: number;
	numberOfCompaniesInMonitor: number;
	numberOfCompaniesInPursuit: number;
}

export interface IWatchlistWidgetData {
	id: number;
	name: string;
	owner?: IPerson;
	status: string;
}

export interface ITag {
	tagId: number;
	name: string;
	type?: string;
	isDefault?: boolean;
}

export interface IProspectDocument {
	companyId: number;
	createdBy: IUser;
	createdOn: string;
	fileName: string;
	id: number;
	updatedOn: string;
}

export interface IProspectLinkedFiles {
	id: number;
	companyId: number;
	title?: string;
	url: string;
	targetType?: string;
}

export interface IWatchlistProspect extends IProspect {
	tags: ITag[];
	subscribers: IPerson[];
	owners: IPerson[];
	files: string[];
	documents: IProspectDocument[];
	linkedFiles: IProspectLinkedFiles[];
	website: string;
	watchlists: {
		id: number;
		name: string;
		status: string;
	}[];
	financialSummary: IFinancialSummary;
	isExistInBIC: boolean;
}

export interface IWatchlistMeta {
	watchlistId: number;
	watchlistName: string;
}

// export interface IWatchlistCompany {
// 	watchlistId: number;
// 	companyId: number;
// 	owner: string;
// 	notes: string;
// 	status: string;
// }

// Analysis Comments
export interface IAnalysisComment {
	id: number;
	parentCommentId?: number;
	title: string;
	createdBy: {
		userId: string;
		firstName: string;
		lastName: string;
		email: string;
	};
	companyId: number;
	analysis: string;
	tags: ITag[];
	createdOn: string;
	updatedOn: string;
	type: string;
	sectionType: string;
	isObsolete: boolean;
	isClosed: boolean;
	replies: IAnalysisComment[];
}

export interface IWatchlistComment {
	id: number;
	parentCommentId?: number;
	createdBy: {
		userId: string;
		firstName: string;
		lastName: string;
		email: string;
	};
	companyId: number;
	companyName: string;
	// watchlistId: number;
	analysis: string;
	createdOn: string;
	updatedOn: string;
	type: string;
	isObsolete: boolean;
	isClosed: boolean;
	reviewedOn: string;
	reviewedBy: {
		email: string;
		firstName: string;
		lastName: string;
		userId: string;
	};
	isReviewed: boolean;
	replies: IWatchlistComment[];
}

// TODO: remove this
// Dashboard Widgets
// interface IDashboardWidget {
// 	commentsCount: number;
// }

// export interface IDashboardSymmaryWidget extends IDashboardWidget {
// 	growth: number;
// 	currentValue: number;
// }

// export interface IProfitLossData {
// 	fiscalYear: number;
// 	margin: number;
// 	currentValue: number;
// }

// export interface IDashboardProfitLossWidget extends IDashboardWidget {
// 	data: IProfitLossData[];
// 	growth: number;
// }

export class DashboardWidgetType {
	static SUMMARY = 'summary';
	static PROFIT_LOSS = 'profitLoss';
}

export class WatchlistCommentType {
	static CURRENT_SITUATION = 'CurrentSituation';
	static AP_VIEW = 'APView';
}

interface IPeersSharePriceData {
	date: string;
	sharePrice: number;
	indexPrice: number;
}

interface IPeersRevenueData {
	companyId: number;
	companyName: string;
	revenue: number;
	revenueUSD?: number;
	revenueGrowth: number;
	ebitMargin: number;
}

interface IPeersRevenueYearData {
	revenue: number;
	revenueGrowth: number;
	ebitMargin: number;
}

export interface IPeersSharePriceWidget {
	companyId: number;
	companyName: string;
	currencyCode: string;
	growth: number;
	sharePrice: IPeersSharePriceData[];
}

export interface IPeersFinancialWidget {
	companyId: number;
	companyName: string;
	revenueCAGR: number;
	ebitdaMargin: number;
	sgaMargin: number;
	leverage: number;
	revenuePerEmployee: number;
	capexPercentageOfRevenue: number;
	year: number;
}

export interface IPeersRevenueWidget {
	revenueGrowthAverage: number;
	revenueGrowthMedian: number;
	ebitAverage: number;
	ebitMedian: number;
	data: IPeersRevenueData[];
}

export interface IPeersRevenueYoYWidget {
	revenueGrowthAverage: number;
	revenueGrowthMedian: number;
	ebitAverage: number;
	ebitMedian: number;
	data: IPeersRevenueYoYData[];
}

export interface IPeersRevenueYoYData {
	currentYear: IPeersRevenueYearData;
	previousYear: IPeersRevenueYearData;
	companyName: string;
	companyId: number;
}

export interface IPeersSharePriceWidget {
	companyId: number;
	companyName: string;
	currencyCode: string;
	growth: number;
	sharePrice: IPeersSharePriceData[];
}

// TODO: rename IDashboardCache
export interface IDashboardWidgetCache {
	scoreData: IScoreData | null;
	// netSales: IDashboardSymmaryWidget | null;
	// operatingIncome: IDashboardSymmaryWidget | null;
	// sga: IDashboardSymmaryWidget | null;
	// ebitda: IDashboardSymmaryWidget | null;

	// // Profit and Loss
	// operationalRevenuePl: IDashboardProfitLossWidget | null;
	// ebitdaPl: IDashboardProfitLossWidget | null;
	// sgaPl: IDashboardProfitLossWidget | null;
	// grossProfitPl: IDashboardProfitLossWidget | null;
}

export interface ICurrentUser {
	userId: string;
	firstName: string;
	lastName: string;
	email: string;
}

export interface ITask {
	id: number;
	name: string;
	description: string;
	companyId: number;
	companyName: string;
	createdById: string;
	createdByFullName: string;
	createdByEmail: string;
	assignedToId: string;
	assignedToFullName: string;
	assignedToEmail: string;
	createdDate: string;
	dueDate: string;
	startedDate: string;
	completedDate: string;
	status: string;
}

export class TaskStatus {
	static TODO = 'ToDo';
	static IN_PROGRESS = 'InProgress';
	static DONE = 'Done';
}

// Watchlist report

export interface IWatchlistReportCompany {
	companyId: number;
	companyName: string;
	industry: string;
	revenue: number;
	ebitda: number;
	status: string;
	comments: IWatchlistComment[];
}

export interface IWatchlistReport {
	companies: IWatchlistReportCompany[];
	watclistId: number;
	watchlistName: string;
	communities: IWatchlistCommunity[];
	industries: IWatchlistIndustry[];
	createdDate: string;
}

export interface IWatchlistReportRequiredData {
	watchlistId: number;
	companies: { companyId: number; comments: number[] }[];
}

// Dashboard
export interface IScoreData {
	overallScore: number;
	workingCapital: number;
	profitability: number;
	sales: number;
	solvency: number;
	marketData: number;
}

export interface IQuarterlyFinancialData {
	calendarQuarter: number;
	calendarYear: number;
	fiscalQuarter: number;
	fiscalYear: number;
	companyId: number;
	periodEndDate: string;
	revenue: number | null;
}

export interface ISummaryData {
	currencyCode: string;
	ceo: string;
	location: string;
	exchange: string;
	ticker: string;
	latestYear: number;
	netSales: {
		currentValue: number;
		growth: number;
	};
	operatingIncome: {
		currentValue: number;
		growth: number;
	};
	sga: {
		currentValue: number;
		growth: number;
	};
	ebitda: {
		currentValue: number;
		growth: number;
	};
	quarterlyFinancialData: IQuarterlyFinancialData[];
}

export interface IProfitAndLossData {
	currencyCode: string;
	revenue: {
		data: {
			fiscalYear: number;
			margin: number;
			currentValue: number;
		}[];
		growth: number;
	};
	ebitda: {
		data: {
			fiscalYear: number;
			margin: number;
			currentValue: number;
		}[];
		growth: number;
	};
	sga: {
		data: {
			fiscalYear: number;
			margin: number;
			currentValue: number;
		}[];
		growth: number;
	};
	grossProfit: {
		data: {
			fiscalYear: number;
			margin: number;
			currentValue: number;
		}[];
		growth: number;
	};
}

export interface IDebtAnalysisData {
	data: [
		{
			fiscalYear: number;
			totalDebt: number;
			netDebt: number;
			netDebtOverEBITDA: number;
		},
	];
	growth: number;
	netDebtOverEbitda: {
		currentValue: number;
		growth: number;
	};
	totalDebtOverAssets: {
		currentValue: number;
		growth: number;
	};
	fcf: {
		currentValue: number;
		growth: number;
	};
	interestCoverage: {
		currentValue: number;
		growth: number;
	};
	currencyCode: string;
}

export interface ICashPositionData {
	currencyCode: string;
	data: [
		{
			fiscalYear: number;
			cash: number;
			operatingCashFlow: number;
			fcf: number;
			capex: number;
		},
	];
}

export interface IProfitAndLossOverviewData {
	fiscalYearsData: {
		fiscalYear: number;
		sales: number;
		cogs: number;
		grossProfit: number;
		sga: number;
		operatingIncome: number;
		profitPerLossForTheYear: number;
		ebitda: number;
		revenueGrowth: number;
		grossProfitMargin: number;
		sgaMargin: number;
		operatingIncomeMargin: number;
		ebitdaMargin: number;

		cogsYoY: number;
		ebitdaYoY: number;
		grossProfitYoY: number;
		operatingIncomeYoY: number;
		profitPerLossForTheYearYoY: number;
		salesYoY: number;
		sgaYoY: number;

		[index: string]: number;
	}[];
	cagR5Years: {
		sales: number;
		cogs: number;
		grossProfit: number;
		sga: number;
		operatingIncome: number;
		profitPerLossForTheYear: number;
		ebitda: number;
	};
}

export interface IBalanceSheetData {
	inventory: number;
	otherCurrentLiabilitis: number;
	otherNonCurrentLiabilities: number;
	totalEquity: number;
	currentAssets: number;
	nonCurrentAssets: number;
	otherNonCurrentAssets: number;
	otherCurrentAssets: number;
	goodwill: number;
	longTermDebt: number;
	currentPortionOfLongTermDebt: number;
	cashAndEquivalents: number;
	accountPayable: number;
	accountReceivable: number;
	totalLiabilitiesAndEquity: number;
	netPropertyPlanAndEquipment: number;
	totalAssets: number;
	fiscalYear: number;

	[index: string]: number;
}

export interface ICashFlowData {
	totalDebtIssued: number;
	totalDebtRepaid: number;
	otherFinancingActivities: number;
	capitalExpenditure: number;
	investmentsInMarketableAndEquitySecurities: number;
	depreciationAmortization: number;
	cashGeneratedFromOperations: number;
	cashGeneratedFromInvesting: number;
	cashGeneratedFromFinancing: number;
	fxEffect: number;
	netInCashAndEquivalents: number;
	profitOfTheYear: number;
	fiscalYear: number;

	[index: string]: number;
}

// export interface IDashboarCache {
// 	scoreData: IScoreData | null;
// }
